import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import WhoAreWe from "../../components/who-are-we"
import SEO from "../../components/seo"

class Sodexo extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-sodexo inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://mysodexo.ma/" title="" target="_blank">
                                <img src="/img/clients/sodexoiconapp.png" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">SODEXO PASS MAROC.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://tacos.ma/" title="See Dolfn!" target="_blank">mysodexo.ma</a>
                            </span>
                            <span className="project__detail">Year 2017</span>
                        </p>

                        <ul className="article__share-list">
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--facebook" target="blank" title="Share on Facebook"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--twitter" target="blank" title="Share on Twitter"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--linkedin" target="blank" title="Share on Linkedin"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                                    className="article__share-link article__share-link--gplus" target="blank" title="Share on Google Plus"></a>
                            </li>
                        </ul>
                    </aside>

                    <h1 className="article__heading">MY SODEXO MAROC.</h1>
                    <p className="article__para">
                        Grâce à son module de géolocalisation, l’application mobile de Sodexo vous permet de localiser rapidement
                        sur carte les commerces autour de vous afin de connaitre les lieux d’utilisation de vos Mida et Cado Pass.
                    </p>
                    <p className="article__para">
                        <span className="project__detail project__detail--small">Webdesign, Developpement web & mobile</span>
                    </p>
                </div>


                <p className="article__belt">
                    <img className="article__belt-image" src="/img/projects/mysodexo/banner-project.png" alt="mobile-apps" />
                </p>

                <div className="article__body">
                    <h2 className="article__heading">Le challenge</h2>
                    <p className="article__para">
                        Concevoir une application intuitive, avec un design responsive, ergonomique et moderne.
                        Souvent, lors de la conception de site Web, le design est réfléchi et créé pour un ordinateur (desktop).
                        Par la suite, le site est décliné pour s’ajuster ou s’adapter aux autres appareils, en terminant par
                        l’interface du téléphone mobile. On décline donc vers le bas. C’est ce qu’on appelle l’approche graceful
                        degradation, ou desktop-first.Malheureusement, tous les éléments visuels et fonctionnalités d’un site Web
                        optimisé pour desktop ne se traduisent pas toujours bien sur un appareil mobile. C’est à ce moment qu’on
                        implique une autre approche, soit celle de progressive enhancement, ou mobile-first.<br />

                        Le designe de l'application MYSODEXO est pensé d'une nouvelle façon. Nous avons adopter une approche de
                        conception mobile-first.<br />

                        Avec cette approche, le design est réfléchi et conçu principalement pour le plus petit appareil mobile,
                        puis décliné vers le haut jusqu’à l’interface pour desktop.
                    </p>

                    <h2 className="article__heading">USER EXPERIENCE</h2>
                    <p className="article__para">
                        Le concept de l'application n'a jamais été formalisé par un cahier de charge ou par une idée clair,
                        l'agence a fournie un grand effort en faisant plusieurs benchmark et en creusant dans plusieurs pistes
                        créative.<br />

                        A l'issue de la phase de reflection et grace aux efforts de la direction géneral et la direction marketing,
                        l'experience utilisateur a été optimiser pour une navigation fluide depuis tous les terminaux.


                    </p>
                </div>

                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/mysodexo/maquette1.png" alt="branding" />
                </p>

                <h2 className="article__heading">Réalisation des maquettes d'interfaces graphiques :</h2>

                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/mysodexo/uxinfoaffilie.png" alt="branding" />
                </p>
                <br /><br />
            </article>
            <WhoAreWe />
        </Layout>
    
  )

}

export default Sodexo
